<div
  *ngIf="{
    isLoading: (isLoading$ | async),
    data: (newsArticle$ | async),
  } as obs$"
>
  <div *ngIf="obs$.isLoading" class="spinner-container">
    <mat-spinner class="absolute-centered" mode="indeterminate" [diameter]="150"></mat-spinner>
  </div>
  <div class="article-container" *ngIf="!obs$.isLoading && obs$.data && obs$.data.newsArticle">
    <div *ngIf="obs$.data.topics.length" class="categories-container">
      <div class="category" *ngFor="let item of obs$.data.topics">{{ item }}</div>
    </div>
    <div class="subject">{{ obs$.data.newsArticle.subject }}</div>
    <div class="date">{{ getDateString(obs$.data.newsArticle.createdDateTime) }}</div>
    <div *ngIf="obs$.data.newsArticle.content" class="rich-text" [innerHtml]="obs$.data.newsArticle.content | parseHtml"></div>
    <div class="keywords-container" *ngIf="obs$.data.newsArticle.keywords.length > 0">
      <div class="label">Keywords:</div>
      <div class="keyword-item" *ngFor="let keyword of obs$.data.newsArticle.keywords">{{ keyword }}</div>
    </div>
  </div>
</div>
